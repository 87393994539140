* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.App {
  text-align: center;
  font-family: 'Ubuntu', 'Poppins', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #00f6f6;
  background: linear-gradient(90deg, #00f6f6, #a7f6f6);
  width: 100%;
  color: #000a0a;
  font-weight: bold;
}

.App-header p {
  margin: 0;
  padding: 1em;
}

.App-main {
  background-color: #000a0a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-Title {
  font-weight: 800;
}

.App-Title span {
  background-color: #00f6f6;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.App-Tagline {
  font-size: 300;
}

.App-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.App-link {
  color: #00f6f6;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
